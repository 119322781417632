import * as React from "react";
import {Grid, Paper,} from "@material-ui/core";
import { Layout } from "../common/Layout";
import { SEO } from "../components/seo";
import { Heading } from "../components/heading";

const MarketplaceScreen = () => {
    return (
        <Layout>
            <SEO title="Marketplace" />
            <Grid className="mb-4" container>
                <Grid item xs={12}>
                    <Heading className="mb-4" text="Marketplace" />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                    <Paper>
                        The marketplace is temporarily offline due to changes in PayPal API requirements for marketplace sellers.
                        We hope to be able to integrate with Stripe Connect to provide a better experience.
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default MarketplaceScreen;
